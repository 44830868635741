document.querySelectorAll('.custom-card-homepage-1 .custom-card-body p').forEach(function (item) {
	item.addEventListener('click', function (event) {
		if(event.target.scrollHeight < event.target.offsetHeight){
			return;
		}

		if (!event.target.classList.contains('expanded')) {
			event.preventDefault();
		}

		event.target.classList.add('expanded');
	})
});